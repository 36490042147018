:root {
  --u-border-radius-2: 2px;
  --u-border-radius-4: 4px;
  --u-border-radius-8: 8px;
  --u-border-radius-12: 12px;
  --u-border-radius-16: 16px;
  --u-border-radius-24: 24px;
  --u-border-radius-full: 9999px;

  --u-box-shadow-soft: 0px 4px 10px rgba(105, 50, 212, 0.1);
  --u-box-shadow-base: 0px 10px 40px rgba(105, 50, 212, 0.2);
  --u-box-shadow-obvious: 0px 10px 20px rgba(105, 50, 212, 0.3);
  --u-box-shadow-main: 0px 20px 40px rgba(0, 0, 0, 0.2);
}
