:root {
  --u-color-black: #000;
  --u-color-white: #fff;

  --u-color-royal-050: #f5f5ff;
  --u-color-royal-100: #e2daff;
  --u-color-royal-200: #cebdff;
  --u-color-royal-300: #a180ff;
  --u-color-royal-400: #8f67ff;
  --u-color-royal-500: #7c4dff;
  --u-color-royal-600: #5f38cc;
  --u-color-royal-700: #412297;
  --u-color-royal-800: #2d1861;
  --u-color-royal-900: #0f0821;

  --u-color-alien-050: #f6fef9;
  --u-color-alien-100: #c4f9d7;
  --u-color-alien-200: #89f4ae;
  --u-color-alien-300: #4eee86;
  --u-color-alien-400: #16e65e;
  --u-color-alien-500: #10ab46;
  --u-color-alien-600: #0d8938;
  --u-color-alien-700: #0a672a;
  --u-color-alien-800: #06441c;
  --u-color-alien-900: #03220e;

  --u-color-cerulean-050: #fafcff;
  --u-color-cerulean-100: #daeaff;
  --u-color-cerulean-200: #b5d4ff;
  --u-color-cerulean-300: #91bfff;
  --u-color-cerulean-400: #6ca9ff;
  --u-color-cerulean-500: #4794ff;
  --u-color-cerulean-600: #066eff;
  --u-color-cerulean-700: #0052c4;
  --u-color-cerulean-800: #003782;
  --u-color-cerulean-900: #001b41;

  --u-color-vitaminC-050: #fffaf5;
  --u-color-vitaminC-100: #ffe2c8;
  --u-color-vitaminC-200: #ffc691;
  --u-color-vitaminC-300: #ffa95a;
  --u-color-vitaminC-400: #ff8d23;
  --u-color-vitaminC-500: #eb7100;
  --u-color-vitaminC-600: #bc5a00;
  --u-color-vitaminC-700: #8d4400;
  --u-color-vitaminC-800: #5e2d00;
  --u-color-vitaminC-900: #2f1700;

  --u-color-coral-050: #fef5f6;
  --u-color-coral-100: #fcc4cb;
  --u-color-coral-200: #f98897;
  --u-color-coral-300: #f54d63;
  --u-color-coral-400: #f2112f;
  --u-color-coral-500: #bb0a21;
  --u-color-coral-600: #96081a;
  --u-color-coral-700: #700614;
  --u-color-coral-800: #4b040d;
  --u-color-coral-900: #250207;

  --u-color-neutral-050: #f8f8f8;
  --u-color-neutral-100: #e2e5e8;
  --u-color-neutral-200: #c6ccd2;
  --u-color-neutral-300: #aab2bb;
  --u-color-neutral-400: #8e99a4;
  --u-color-neutral-500: #485868;
  --u-color-neutral-600: #384757;
  --u-color-neutral-700: #2a3643;
  --u-color-neutral-800: #1c2630;
  --u-color-neutral-900: #161c23;
}
