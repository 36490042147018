:root {
  --u-font-family-roobert-pro: 'Roobert PRO';

  --u-font-family-primary: var(--u-font-family-roobert-pro);

  --u-text-gargantuan-font-size: 56px;
  --u-text-gargantuan-line-height: 64px;

  --u-text-colossal-font-size: 48px;
  --u-text-colossal-line-height: 56px;

  --u-text-massive-font-size: 40px;
  --u-text-massive-line-height: 48px;

  --u-text-huge-font-size: 32px;
  --u-text-huge-line-height: 40px;

  --u-text-big-font-size: 24px;
  --u-text-big-line-height: 32px;

  --u-text-large-font-size: 20px;
  --u-text-large-line-height: 28px;

  --u-text-medium-font-size: 18px;
  --u-text-medium-line-height: 28px;
  --u-text-medium-callout-letter-spacing: 2px;

  --u-text-base-font-size: 16px;
  --u-text-base-line-height: 24px;
  --u-text-base-callout-letter-spacing: 2px;

  --u-text-small-font-size: 14px;
  --u-text-small-line-height: 20px;
  --u-text-small-callout-letter-spacing: 2px;

  --u-text-tiny-font-size: 12px;
  --u-text-tiny-line-height: 16px;
  --u-text-tiny-callout-letter-spacing: 1px;

  --u-text-miniscule-font-size: 10px;
  --u-text-miniscule-line-height: 12px;
  --u-text-miniscule-callout-letter-spacing: 1px;

  --u-text-superMiniscule-font-size: 8px;
  --u-text-superMiniscule-line-height: 10px;
  --u-text-superMiniscule-callout-letter-spacing: 1px;
}
