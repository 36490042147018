:root {
  --u-spacing-2: 2px;
  --u-spacing-4: 4px;
  --u-spacing-8: 8px;
  --u-spacing-12: 12px;
  --u-spacing-16: 16px;
  --u-spacing-20: 20px;
  --u-spacing-24: 24px;
  --u-spacing-32: 32px;
  --u-spacing-40: 40px;
  --u-spacing-48: 48px;
  --u-spacing-56: 56px;
  --u-spacing-64: 64px;
  --u-spacing-72: 72px;
  --u-spacing-96: 96px;
  --u-spacing-104: 104px;
  --u-spacing-128: 128px;
  --u-spacing-144: 144px;
  --u-spacing-160: 160px;
  --u-spacing-256: 256px;
}
